import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { cardData } from "../../data/menuData"
function CardSection() {
  return (
    <Wrapper>
      <ContentWrapper>
        {cardData.map((item, index) => (
          <Card>
            <Link to={item.link} key={index}>
              <img src={item.bg} alt={item.title} />
              <CardImg>
                <img src={item.icon} alt={item.title} />
              </CardImg>
            </Link>
          </Card>
        ))}
      </ContentWrapper>
    </Wrapper>
  )
}
export default CardSection

const Wrapper = styled.div`
  background: linear-gradient(#ff7373 0%, #491eb8 100%);
  overflow: hidden;
`
const ContentWrapper = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  padding: 200px 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  justify-items: center;

  @media (max-width: 1060px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const Card = styled.div`
  width: 240px;
  height: 330px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  :hover {
    transform: scale(1.1, 1.1);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
  }
`
const CardImg = styled.div`
  position: absolute;
  top: 10px;
  padding: 20px;
  justify-items: center;
`
